import { CustomFormWrapper } from "./containers/CustomFormWrapper";
import { useRef, useState, useEffect } from "react";
import { getCustomFormFields, postPlaceService, getServicePoll } from "./services/Api"
import { initI18, initDefaultLocale } from './services/i18n';
import { translate } from "react-i18next";
import { t } from 'i18next';
import QueryString from "query-string";
import Modal from "./containers/Modal";
import PlaceCallPopUp from "./components/PlaceCallPopUp";
import InProccessPopup from "./components/InProccessPopup"
import FIELDCONSTANTS from "../src/common/Constants/FormFieldMappings";
import { getPayloadData } from "../src/helpers/PayLoadData";
import cloneDeep from 'lodash.clonedeep';
import AccessDenied from './components/AccessDenied';

let clonedFormFields = {};

function App() {

	const childRef = useRef(null);

	const [formFields, setFormFields] = useState();
	const [showModal, setShowModal] = useState(false);
	const [uitheme, setuitheme] = useState('light');
	const [user, setUser] = useState();
	const [isBuildingSelecetd, setIsBuildingSelecetd] = useState(false);
	const [submitDisabled, setSubmitDisabled] = useState(true);
	const [errorMessage, setErrorMessage] = useState();
	const [isAccessError, setIsAccessError] = useState(false);
	const [intervalID, setIntervalID] = useState();
	const [postWait, setPostWait] = useState(false);
	const [callerid, setCallerid] = useState('');
	const [pollUnitId, setPollUnitId] = useState('');
	const [InprocessModal, setInprocessModal] = useState(false);
	const [InprocessStep, setInprocessStep] = useState(1);
	const [statusCode, setStatusCode] = useState();
	const [displayMessage, setDisplayMessage] = useState('');

	// const [polltimeoutstate,setPolltimeoutstate]=useState(false);
	const pollinterval = 5000;
	const polltimeout = process.env.REACT_APP_SERVICE_REQUEST_TIMEOUT;

	useEffect(() => {
		if (!user) {
			window.addEventListener("message", (event) => {

				let cpdata = event?.data?.cndtoken;
				let lang = event?.data?.lang?.toLowerCase();
				let theme = event?.data?.theme;
				let objuser = event?.data?.objuser;

				if (theme) {
					setuitheme(theme)
					document.body.style.backgroundColor = theme === 'dark' ? '#021125' : '#f6f6f8';
				};
				if (objuser) { setUser(objuser) };
				if (cpdata) { initI18(lang, cpdata) };

			}, false);

			localStorage.setItem('access.token', getTokenFromCP());
		}

		if (!formFields) {
			getCustomFormFields().then(res => {
				if (res !== '') {
					setIsAccessError(false)
					clonedFormFields = cloneDeep(res)
					setFormFields(res);
				} else {
					setIsAccessError(true)
				}
			}).catch(e => console.error(e));
		}
		let viewas_Id = (window.location.href.indexOf('viewas_Id'));
		if (viewas_Id > -1) {
			let button = document.querySelectorAll('button')
			button.forEach(element => {
				element.disabled = true;
			})
		} else {
			if (postWait && !intervalID && callerid) {
				let timeout = polltimeout;
				let interval = pollinterval;
				let vcaller = callerid;
				let vunit = pollUnitId;
				const intervalID = setInterval(() => {
					//	if(callerid){
					getServicePoll(vcaller, vunit).then((res) => {
						if (InprocessStep == 1) {
							setInprocessStep(2);
						}
						if (res.status == 200) {
							setInprocessModal(false);
							clearInterval(intervalID); // Stop the interval if the condition holds true
							setPostWait(false);
							if (res.data.status) {
								setErrorMessage(false);
								setShowModal(true);
							}
							else {
								setIntervalID()
								setErrorMessage(true);
								setShowModal(true);
								setInprocessModal(false);

							}
							setCallerid('');
							setStatusCode(res.data.status_code);
							setDisplayMessage(getDisplayMessage(res.data.status_code))
						}

					}).catch((e) => {
						console.log(e);
						setIntervalID()
						setErrorMessage(true);
						setShowModal(true)
						setInprocessModal(false);
						setCallerid('');
						setDisplayMessage(getDisplayMessage(1))
					});
					//}
					console.log('poll api call...')
				}, interval);

				setIntervalID(intervalID)

				setTimeout(() => {
					clearInterval(intervalID);
					setErrorMessage(true);
					setShowModal(true)
					setInprocessModal(false);
					setCallerid('');
					setDisplayMessage(getDisplayMessage(1));
					setPostWait(false);
				}, timeout)

			}

			if (!postWait && intervalID) {
				clearInterval(intervalID); // Stop the interval if the condition holds true
				setPostWait(false);
			}
		}
		return window.removeEventListener("message", () => { }, true)

	}, [formFields, postWait, callerid]);

	const getDisplayMessage = (code) => {
		switch (code) {
			case 0:
				return t('ServiceRequestRaised.Success.Body');
			case 1:
				return t('ServiceRequest.Error.Body');
			case 2:
				return t('ServiceRequest.Success.Body');
			case 3:
				return t('ServiceRequest.unit.Error.Body');
			default:
				return t('ServiceRequest.Error.Body');
		}
	};

	const getTokenFromCP = () => {
		let viewAsQueryParam = QueryString.parse(
			window.location.search
		);
		return viewAsQueryParam["key"];
	};

	const checkIsValid = () => {
		let data = childRef.current.getFormValue();

		switch (user.countryId) {
			case "3":
				if ((data.customer_perception_code != null && data.description != "" && data.mode_of_communication != null)
					&& (data.building_name != "" || data.unit_name != "")) {
					setSubmitDisabled(false);
				}
				break;
			case "22":
				if ((data.customer_perception_code != null && data.description != "" && data.response_preference != null)
					&& (data.building_name != "" || data.unit_name != "")) {
					setSubmitDisabled(false);
				}
				break;
			case "55":
				if ((data.customer_perception_code != null && data.description != "" && data.response_preference != null)
					&& (data.building_name != "" || data.unit_name != "")) {
					setSubmitDisabled(false);
				}
				break;
			case "13":
				if ((data.unit_out_of_service != null && data.Vandalism_act != null && data.description != ""
					&& data.response_preference != null && data.door != null) && (data.building_name != "" || data.unit_name != "")) {
					setSubmitDisabled(false);
				}
				break;
			case "15":
				if ((data.unit_out_of_service != null && data.description != "" && data.response_preference != null && data.door != null)
					&& (data.building_name != "" || data.unit_name != "")) {
					setSubmitDisabled(false);
				}
				break;
			case "17":
			case "12":
			case "5":
				if ((data.unit_out_of_service != null && data.description != "" && data.response_preference != null && data.customer_perception_code != null)
					&& (data.building_name != "" || data.unit_name != "")) {
					setSubmitDisabled(false);
				}
				break;
			case "11":
				if ((data.customer_perception_code != null && data.description != "" && data.response_preference != null && data.floor != "")
					&& (data.building_name != "" || data.unit_name != "")) {
					setSubmitDisabled(false);
				}
				break;
			default:
				break;
		}
	}

	const handleChange = (fullForm, formData) => (event) => {
		setSubmitDisabled(true);

		if (FIELDCONSTANTS["ServiceRequest.DescriptionOfIncident.Description.Placeholder"] == event.target.name) {
			childRef.current.customSetCharacterCount(event.target.value.length);
		}
		//User Story 864187: INC0036114
		//purpose- Place Service Request - Missing response_preference_EmailText field from italy payload 
		if (["22", "55", "13"].includes(user.countryId)) {
			if (FIELDCONSTANTS["ServiceRequest.ErrorType.Placeholder"] == event.target.name) {
				childRef.current.setFormValueCustom({ "CustomerPerceptionEmailText": t(event.target.getAttribute("display_id")) });
			}
			if (FIELDCONSTANTS["ServiceRequest.ResponsePreference.DropDown.Placeholder"] == event.target.name) {
				childRef.current.setFormValueCustom({ "response_preference_EmailText": t(event.target.getAttribute("display_id")) });
			}
		}

		if (["15", "17"].includes(user.countryId)) {
			if (FIELDCONSTANTS["ServiceRequest.ResponsePreference.DropDown.Placeholder"] == event.target.name) {
				childRef.current.setFormValueCustom({ "response_preference_EmailText": t(event.target.getAttribute("display_id")) });
			}
			if (FIELDCONSTANTS["ServiceRequest.Problemstatement.fieldName1"] == event.target.name) {
				childRef.current.setFormValueCustom({ "CustomerPerceptionEmailText": t(event.target.getAttribute("display_id")) });
			}
		}

		if (["11"].includes(user.countryId)) {
			if (FIELDCONSTANTS["ServiceRequest.ResponseTime.fieldName"] == event.target.name) {
				childRef.current.setFormValueCustom({ "response_preference_EmailText": t(event.target.getAttribute("display_id")) });
			}
			if (FIELDCONSTANTS["ServiceRequest.Problemstatement.fieldName"] == event.target.name) {
				childRef.current.setFormValueCustom({ "CustomerPerceptionEmailText": t(event.target.getAttribute("display_id")) });
			}
		}

		let arrSelectedUnits = [];
		let arrSelectedBuildings = [];

		if (event.target.name == FIELDCONSTANTS["ServiceRequest.Location.DropDown.Placeholder.title1"]) {
			if (event.target.value !== '') {
				setIsBuildingSelecetd(true)
				let buildings = clonedFormFields.sections[0].child[0].child.find(item => item.short_building_address == event.target.value);

				let buildingIds = clonedFormFields.sections[0].child[0].child.filter(item => item.short_building_address == event.target.value).map(item => item.building_id);
				let newUnitOptions;

				if (buildingIds.length > 1) {
					newUnitOptions = clonedFormFields.sections[0].child[1].child.filter((item) => buildingIds.includes(item.building_id));
				} else {
				    newUnitOptions = clonedFormFields.sections[0].child[1].child.filter((item) => item.building_id == buildings.building_id);
				}

				if (newUnitOptions.length > 0) {
					newUnitOptions[0]["selected"] = true;
					fullForm.sections[0].child[1].child = newUnitOptions;
				}

				fullForm.sections[0].child[0].child.map((item, index) => {
					if (item.value == event.target.value) {
						fullForm.sections[0].child[0].child[index]["selected"] = true;
					} else {
						fullForm.sections[0].child[0].child[index]["selected"] = false;
					}
				})

				childRef.current.setFormValueCustom({ "building_address": event.target.value });

			} else {
				fullForm.sections[0].child[1].child = clonedFormFields.sections[0].child[1].child;
				fullForm.sections[0].child[0].child = clonedFormFields.sections[0].child[0].child;

				arrSelectedUnits = fullForm.sections[0].child[1].child.filter((item) => item.selected == true);
				if (arrSelectedUnits.length > 0) {
					fullForm.sections[0].child[1].child.map((item, index) => {
						if (arrSelectedUnits.includes(item)) {
							fullForm.sections[0].child[1].child[index]["selected"] = false;
						}
					})
				}
				childRef.current.setFormValueCustom({ [FIELDCONSTANTS["ServiceRequest.Location.DropDown.Placeholder.title2"]]: "" });
				childRef.current.setFormValueCustom({ "building_address": "" });
			}

			childRef.current.setFomrNewValue(fullForm);
		}

		if (event.target.name == FIELDCONSTANTS["ServiceRequest.Location.DropDown.Placeholder.title2"]) {
			if (event.target.value !== '') {
				setIsBuildingSelecetd(false)
				let units = clonedFormFields.sections[0].child[1].child.find(item => item.unit_name == event.target.value);
				let newBuildingOptions = clonedFormFields.sections[0].child[0].child.filter((item) => item.building_id == units.building_id);

				if (newBuildingOptions.length > 0) {
					fullForm.sections[0].child[0].child.map((item, index) => {
						if (newBuildingOptions[0]["building_id"] == item.building_id) {
							fullForm.sections[0].child[0].child[index]["selected"] = true;
							childRef.current.setFormValueCustom({ "building_address": fullForm.sections[0].child[0].child[index]["name"] });
						}
					})
				}

				fullForm.sections[0].child[1].child.map((item, index) => {
					if (item.value == event.target.value) {
						fullForm.sections[0].child[1].child[index]["selected"] = true;
					} else {
						fullForm.sections[0].child[1].child[index]["selected"] = false;
					}
				})
			} else {
				fullForm.sections[0].child[0].child = clonedFormFields.sections[0].child[0].child;
				fullForm.sections[0].child[1].child = clonedFormFields.sections[0].child[1].child;

				arrSelectedBuildings = fullForm.sections[0].child[0].child.filter((item) => item.selected == true);
				if (arrSelectedBuildings.length > 0) {
					fullForm.sections[0].child[0].child.map((item, index) => {
						if (arrSelectedBuildings.includes(item)) {
							fullForm.sections[0].child[0].child[index]["selected"] = false;
						}
					})
				}

				arrSelectedUnits = fullForm.sections[0].child[1].child.filter((item) => item.selected == true);
				if (arrSelectedUnits.length > 0) {
					fullForm.sections[0].child[1].child.map((item, index) => {
						if (arrSelectedUnits.includes(item)) {
							fullForm.sections[0].child[1].child[index]["selected"] = false;
						}
					})
				}
				childRef.current.setFormValueCustom({ [FIELDCONSTANTS["ServiceRequest.Location.DropDown.Placeholder.title1"]]: "" });
				childRef.current.setFormValueCustom({ "building_address": "" });
			}

			childRef.current.setFomrNewValue(fullForm);
		}
		checkIsValid();
	};

	const handleSubmit = () => {
		let postData = childRef.current.getFormValue();

		let postServiceRequestData = getPayloadData(postData, isBuildingSelecetd, formFields, user);

		postPlaceService(postServiceRequestData).then((res) => {

			if (res.status == 202) {
				let calledid = res?.data?.call_back_id;
				let unitid = res?.data?.unit_id;
				setCallerid(calledid);
				setPollUnitId(unitid);
				setPostWait(true);
				setInprocessModal(true);
				setInprocessStep(1);

			} else {
				setPostWait(false);
				setErrorMessage(true);
				setShowModal(true)
				setInprocessModal(false);
				setDisplayMessage(getDisplayMessage(res?.data?.status_code))
			}
		}).catch((e) => {
			setPostWait(false);
			setErrorMessage(true);
			setShowModal(true)
			setDisplayMessage(getDisplayMessage(1))
		});
		setPostWait(true)
		console.log("in handle submit---", postServiceRequestData);

	};

	const handleReset = () => {
		childRef.current.customReset();
		setSubmitDisabled(true);
		childRef.current.customSetCharacterCount(0);
		formFields.sections[0].child[1].child = clonedFormFields.sections[0].child[1].child

		formFields.sections[0].child[1].child.map((item, index) => {
			if (item.selected == true) {
				formFields.sections[0].child[1].child[index]["selected"] = false;
			}
		})
	};

	const togglePopup = () => {
		setShowModal(!showModal)
	}
	return (
		<div className={uitheme == 'dark' ? 'dark' : 'light'}>
			<div className="col-xl-7 col-lg-8 col-md-12 col-sm-12 col-12-md-7">
				<div className="service-request-modal mx-3 mx-lg-2 mb-3 mb-md-2">
					{formFields ?
						(
							<>
								{showModal && <Modal closePopup={togglePopup} >
									<PlaceCallPopUp errorMessage={errorMessage}
										togglePopup={togglePopup} statusCode={statusCode} displayMessage={displayMessage}
									/>
								</Modal>
								}
								{InprocessModal && !showModal && <Modal isCloseIconVisible={false} >
									<InProccessPopup step={InprocessStep}
									/>
								</Modal>
								}
								<CustomFormWrapper
									key="wrapper"
									ref={childRef}
									handleChange={handleChange}
									formArray={formFields}
									t={t}
									userDetails={user}
								/>
								<div className="ButtonsBlock" id="GridButton">
									<button id="ButtonReset" onClick={handleReset} data-auto="cp-PSCButtonReset2NA" name="cancelCourse" value="cancel" type="button" className="button outline-button">{t('Button.Reset')}</button>
									<button className="button" onClick={handleSubmit} type="submit" id="BSubmit" data-auto="cp-PSCBSubmitbtnsaveNA" disabled={submitDisabled}>{t('ServiceRequest.Header')}</button>
								</div>
								{/* <button id="test" onClick={test} data-auto="cp-PSCButtonReset2NA" name="cancelCourse" value="cancel" type="button" className="button outline-button">test</button> */}

							</>
						)
						: isAccessError == true ?
							(
								<AccessDenied />
							) :
							(
								<div className="spinnerdiv">
									<div className="bt-spinner"></div>
								</div>
							)
					}
				</div>
			</div>
			{postWait && !InprocessModal && !showModal && <div className="postspinnerdiv">
				<div className="bt-spinner"></div>
			</div>}
		</div>
	);
}
export default translate('translations')(App);